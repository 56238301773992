<template>
  <v-tooltip
    :disabled="!dot && !tooltip"
    :color="status_color"
    top
  >
    <template #activator="{ on }">
      <v-badge
        :value="badge"
        :content="badge"
        bordered
        offset-x="8"
        overlap
        :color="status_color"
        :class="{ 'block': block }"
      >
        <v-chip
          v-bind="$attrs"
          :label="!dot"
          small
          :dark="is_dark"
          :color="status_color"
          v-on="on"
        >
          <v-icon
            v-if="icon"
            left
            class="ml-0 mr-1"
            v-text="icon"
          />
          <span
            v-if="text"
            v-text="text"
          />
          <span v-else-if="!dot">{{ status_text | capitalize }}</span>
        </v-chip>
      </v-badge>
    </template>
    {{ ($te(`statuses.${status}`) ? $t(`statuses.${status}`) : status) | capitalize }}
    <template v-if="tooltip">
      - {{ tooltip }}
    </template>
  </v-tooltip>
</template>
<script>
  export default {
    props: {
      status: {
        type: String,
        default: '',
      },
      type: {
        type: String,
        default: 'lab results',
      },
      reason: {
        type: String,
        default: null,
      },
      dot: {
        type: Boolean,
        default: false,
      },
      icon: {
        type: String,
        default: null,
      },
      badge: {
        type: [Number, String],
        default: null,
      },
      text: {
        type: [String, Number],
        default: null,
      },
      block: Boolean,
    },
    data: () => ({
      colors: {
        'lab results': {
          'data extraction': 'cyan',
          'do not interpret': 'brown darken-3',
          'interpreted': 'green',
          'needs review': 'error',
          'negative': 'blue-grey',
          'new': 'orange darken-1',
          'in progress': 'orange darken-1',
          'pending approval': 'purple',
          'high confidence': 'purple darken-4',
          'quality assurance': 'pink lighten-1',
          'contact lab': 'blue lighten-1',
          'tech support': 'yellow darken-2 theme--light',
          'unclassified data': 'brown lighten-1',
        },
        'one choice reports': {
          'complete': 'green',
          'negative': 'blue-grey',
          'in progress': 'orange darken-1',
          'flagged': 'error',
        },
        prescriptions: {
          'active': 'green',
          'changed': 'green darken-2',
          'inactive': '',
        },
        reports: {
          'needs review': 'error',
          'pending approval': 'purple',
          'ready': 'green',
          'in progress': 'orange darken-1',
        },
      },
    }),
    computed: {
      status_text () {
        let status = this.$te(`statuses.${this.status}`) ? this.$t(`statuses.${this.status}`) : this.status
        if (this.$auth.user().client) {
          return status
        } else {
          if (this.status === 'high confidence' && this.reason) {
            if (this.reason == 'RR') {
              return status + ': Rel Res'
            } else {
              return status + ': ' + this.$options.filters.percentage(this.reason)
            }
          } else {
            return status
          }
        }
      },
      lowercase_status () {
        return this.status.toLowerCase()
      },
      status_color () {
        return this.colors[this.type][this.lowercase_status]
      },
      is_dark () {
        if (['tech support', 'partial data'].includes(this.lowercase_status)) {
          return false
        }
        return true
      },
      tooltip () {
        if (['needs review', 'contact lab', 'tech support'].includes(this.lowercase_status)) {
          return this.reason
        }
        return false
      },
    },
  }
</script>
<style lang="scss">
  td .v-chip .v-chip__content,
  td .v-chip .v-chip__content span {
    width: 100%;
  }
  .v-badge.block {
    width: 100%;

    .v-chip {
      width: 100%;
      text-align: center;
      display: block
    }
  }
</style>
<style scoped>
  td .v-chip {
    text-align: center;
    padding: 0 5px;
    width: 100%;
  }
  .v-chip.v-size--small .v-icon {
    font-size: 16px;
    height: 16px;
    width: 16px;
}
</style>
